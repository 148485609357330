// import React, { useEffect, useState } from "react";
// import {
//   Box,
//   Avatar,
//   Dialog,
//   TextField,
//   Typography,
//   FormControl,
//   OutlinedInput,
//   InputAdornment,
//   InputLabel,
//   Radio,
//   RadioGroup,
//   FormControlLabel,
// } from "@mui/material";
// import Button from "@mui/material/Button";
// import { getDatabase, ref, get, set } from "firebase/database";
// import IconButton from "@mui/material/IconButton";
// import CloseIcon from "@mui/icons-material/Close";
// import { pink } from "@mui/material/colors";
// import socket from "../socket/SocketService";

// const SubscriptionData = ({ open, onClose, notification }) => {
//   const [subscriptioInfo, setSubscriptionInfo] = useState([]);
//   const [selectedPlan, setSelectedPlan] = useState("Basic");

//   const getData = () => {
//     const db = getDatabase();
//     const dbRef = ref(db, "subscription");

//     get(dbRef)
//       .then((snapshot) => {
//         if (snapshot.exists()) {
//           const data = snapshot.val();
//           const subscriptions = Object.values(data);
//           const defaultPlan = subscriptions.find((sub) => sub.default);
//           if (defaultPlan) {
//             setSelectedPlan(defaultPlan.name);
//           }
//           setSubscriptionInfo(subscriptions);
//         } else {
//           setSubscriptionInfo([]);
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//       });
//   };

//   useEffect(() => {
//     getData();
//   }, []);

//   const handlePriceChange = (index, period, value) => {
//     const updatedSubscriptions = [...subscriptioInfo];
//     updatedSubscriptions[index].price[period] = value;
//     setSubscriptionInfo(updatedSubscriptions);
//   };

//   const handleSave = () => {
//     const updatedSubscriptions = subscriptioInfo.map((sub) => ({
//       ...sub,
//       default: sub?.name === selectedPlan,
//     }));
//     const db = getDatabase();
//     const dbRef = ref(db, "subscription");
//     set(dbRef, updatedSubscriptions)
//       .then(() => {
//         socket.emit("updateplan", { payload: updatedSubscriptions });
//         console.log("Updated Info:", updatedSubscriptions);
//       })
//       .catch((error) => {
//         console.error("Error updating data:", error);
//       });
//   };

//   const handleRadioChange = (event) => {
//     setSelectedPlan(event.target.value);
//   };

//   return (
//     <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl">
//       <IconButton
//         edge="end"
//         color="inherit"
//         onClick={onClose}
//         aria-label="close"
//         sx={{
//           position: "absolute",
//           top: 5,
//           right: 20,
//         }}
//       >
//         <CloseIcon />
//       </IconButton>

//       <Box
//         component="form"
//         sx={{
//           width: "100%",
//           padding: 4,
//           borderRadius: 3,
//           boxShadow: 4,
//           display: "flex",
//           flexDirection: "column",
//           alignItems: "center",
//           gap: 3,
//           backgroundColor: "#f7f9fc",
//           border: "1px solid #e0e0e0",
//         }}
//       >
//         <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
//           Subscription Plans
//         </Typography>

//         <RadioGroup
//           row
//           aria-label="subscription-plans"
//           name="subscription-plans"
//           value={selectedPlan}
//           onChange={handleRadioChange}
//           sx={{
//             display: "flex",
//             justifyContent: "space-around",
//             width: "100%",
//           }}
//         >
//           {["Basic", "Standard", "Premium"].map((plan) => (
//             <FormControlLabel
//               key={plan}
//               value={plan}
//               control={<Radio />}
//               label={null}
//               sx={{
//                 margin: "0 10px",
//                 color: selectedPlan === plan ? "#1976d2" : "#000000",
//                 fontWeight: "bold",
//               }}
//             />
//           ))}
//         </RadioGroup>

//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "space-between",
//             width: "100%",
//             gap: 3,
//           }}
//         >
//           {subscriptioInfo.map((subscription, index) => (
//             <Box
//               key={index}
//               sx={{
//                 display: "flex",
//                 flexDirection: "column",
//                 alignItems: "center",
//                 padding: 2,
//                 width: "30%",
//                 border: `1px solid ${selectedPlan === subscription?.name ? "#1976d2" : "#e0e0e0"}`,
//                 borderRadius: "8px",
//                 backgroundColor: "#ffffff",
//                 boxShadow: 2,
//               }}
//             >
//               <Typography variant="h5" sx={{ fontWeight: "bold" }}>
//                 {subscription?.name}
//               </Typography>

//               <Box sx={{ marginTop: 1, marginBottom: 3 }}>
//                 {subscription?.data?.map((feature, idx) => (
//                   <Typography key={idx} variant="body2">
//                     - {feature}
//                   </Typography>
//                 ))}
//               </Box>

//               <FormControl fullWidth sx={{ marginBottom: 2 }}>
//                 <InputLabel htmlFor="outlined-adornment-amount">Monthly</InputLabel>
//                 <OutlinedInput
//                   id="outlined-adornment-amount"
//                   startAdornment={<InputAdornment position="start">₦</InputAdornment>}
//                   label="Monthly"
//                   value={subscription?.name === "Basic" ? 0 : subscription?.price?.monthly || ""}
//                   onChange={(e) =>
//                     subscription?.name !== "Basic" &&
//                     handlePriceChange(index, "monthly", e.target.value)
//                   }
//                   disabled={subscription?.name === "Basic"}
//                 />
//               </FormControl>

//               <FormControl fullWidth sx={{ marginBottom: 2 }}>
//                 <InputLabel htmlFor="outlined-adornment-amount">Quarterly</InputLabel>
//                 <OutlinedInput
//                   id="outlined-adornment-amount"
//                   startAdornment={<InputAdornment position="start">₦</InputAdornment>}
//                   label="Quarterly"
//                   value={subscription?.name === "Basic" ? 0 : subscription?.price?.quarterly || ""}
//                   onChange={(e) =>
//                     subscription?.name !== "Basic" &&
//                     handlePriceChange(index, "quarterly", e.target.value)
//                   }
//                   disabled={subscription?.name === "Basic"}
//                 />
//               </FormControl>

//               <FormControl fullWidth>
//                 <InputLabel htmlFor="outlined-adornment-amount">Yearly</InputLabel>
//                 <OutlinedInput
//                   id="outlined-adornment-amount"
//                   startAdornment={<InputAdornment position="start">₦</InputAdornment>}
//                   label="Yearly"
//                   value={subscription?.name === "Basic" ? 0 : subscription?.price?.yearly || ""}
//                   onChange={(e) =>
//                     subscription?.name !== "Basic" &&
//                     handlePriceChange(index, "yearly", e.target.value)
//                   }
//                   disabled={subscription?.name === "Basic"}
//                 />
//               </FormControl>
//             </Box>
//           ))}
//         </Box>

//         <Button
//           type="button"
//           variant="contained"
//           sx={{
//             width: "40%",
//             backgroundColor: "#1976d2",
//             color: "#ffffff",
//             fontWeight: "bold",
//             padding: "12px 0",
//             fontSize: "16px",
//             textTransform: "none",
//             borderRadius: "8px",
//             "&:hover": {
//               backgroundColor: "#1565c0",
//             },
//           }}
//           onClick={handleSave}
//         >
//           SAVE
//         </Button>
//       </Box>
//     </Dialog>
//   );
// };

// export default React.memo(SubscriptionData);

import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Avatar,
  Dialog,
  TextField,
  Typography,
  FormControl,
  OutlinedInput,
  InputAdornment,
  InputLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import Button from "@mui/material/Button";
import { getDatabase, ref, get, set } from "firebase/database";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import socket from "../socket/SocketService";

const SubscriptionData = ({ open, onClose, notification }) => {
  const [subscriptioInfo, setSubscriptionInfo] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState("Basic");

  const getData = useCallback(() => {
    const db = getDatabase();
    const dbRef = ref(db, "subscription");

    get(dbRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          const subscriptions = Object.values(data);
          console.log(subscriptions)
          const defaultPlan = subscriptions.find((sub) => sub.default);
          if (defaultPlan) {
            setSelectedPlan(defaultPlan.name);
          }
          setSubscriptionInfo(subscriptions);
        } else {
          setSubscriptionInfo([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  const handlePriceChange = (index, period, value) => {
    const updatedSubscriptions = [...subscriptioInfo];
    updatedSubscriptions[index].price[period] = value;
    setSubscriptionInfo(updatedSubscriptions);
  };

  const handleSave = () => {
    const updatedSubscriptions = subscriptioInfo.map((sub) => ({
      ...sub,
      default: sub?.name === selectedPlan,
    }));
    const db = getDatabase();
    const dbRef = ref(db, "subscription");
    if (JSON.stringify(updatedSubscriptions) !== JSON.stringify(subscriptioInfo)) {
      set(dbRef, updatedSubscriptions)
        .then(() => {
          socket.emit("updateplan", { payload: updatedSubscriptions });
          console.log("Updated Info:", updatedSubscriptions);
          onClose();
        })
        .catch((error) => {
          console.error("Error updating data:", error);
        });
    }
  };

  const handleRadioChange = (event) => {
    setSelectedPlan(event.target.value);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl">
      <IconButton
        edge="end"
        color="inherit"
        onClick={onClose}
        aria-label="close"
        sx={{
          position: "absolute",
          top: 5,
          right: 20,
        }}
      >
        <CloseIcon />
      </IconButton>

      <Box
        component="form"
        sx={{
          width: "100%",
          padding: 4,
          borderRadius: 3,
          boxShadow: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 3,
          backgroundColor: "#f7f9fc",
          border: "1px solid #e0e0e0",
        }}
      >
        <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
          Subscription Plans
        </Typography>

        <RadioGroup
          row
          aria-label="subscription-plans"
          name="subscription-plans"
          value={selectedPlan}
          onChange={handleRadioChange}
          sx={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
          }}
        >
          {["Basic", "Standard", "Premium"].map((plan) => (
            <FormControlLabel
              key={plan}
              value={plan}
              control={<Radio />}
              label={null}
              sx={{
                margin: "0 10px",
                color: selectedPlan === plan ? "#1976d2" : "#000000",
                fontWeight: "bold",
              }}
            />
          ))}
        </RadioGroup>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            gap: 3,
          }}
        >
          {subscriptioInfo.map((subscription, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: 2,
                width: "30%",
                border: `1px solid ${selectedPlan === subscription?.name ? "#1976d2" : "#e0e0e0"}`,
                borderRadius: "8px",
                backgroundColor: "#ffffff",
                boxShadow: 2,
              }}
            >
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {subscription?.name}
              </Typography>

              <Box sx={{ marginTop: 1, marginBottom: 3 }}>
                {subscription?.data?.map((feature, idx) => (
                  <Typography key={idx} variant="body2">
                    - {feature}
                  </Typography>
                ))}
              </Box>

              <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <InputLabel htmlFor="outlined-adornment-amount">Monthly</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-amount"
                  startAdornment={<InputAdornment position="start">₦</InputAdornment>}
                  label="Monthly"
                  value={subscription?.name === "Basic" ? 0 : subscription?.price?.monthly || ""}
                  onChange={(e) =>
                    subscription?.name !== "Basic" &&
                    handlePriceChange(index, "monthly", e.target.value)
                  }
                  disabled={subscription?.name === "Basic"}
                />
              </FormControl>

              <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <InputLabel htmlFor="outlined-adornment-amount">Quarterly</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-amount"
                  startAdornment={<InputAdornment position="start">₦</InputAdornment>}
                  label="Quarterly"
                  value={subscription?.name === "Basic" ? 0 : subscription?.price?.quarterly || ""}
                  onChange={(e) =>
                    subscription?.name !== "Basic" &&
                    handlePriceChange(index, "quarterly", e.target.value)
                  }
                  disabled={subscription?.name === "Basic"}
                />
              </FormControl>

              <FormControl fullWidth>
                <InputLabel htmlFor="outlined-adornment-amount">Yearly</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-amount"
                  startAdornment={<InputAdornment position="start">₦</InputAdornment>}
                  label="Yearly"
                  value={subscription?.name === "Basic" ? 0 : subscription?.price?.yearly || ""}
                  onChange={(e) =>
                    subscription?.name !== "Basic" &&
                    handlePriceChange(index, "yearly", e.target.value)
                  }
                  disabled={subscription?.name === "Basic"}
                />
              </FormControl>
            </Box>
          ))}
        </Box>

        <Button
          type="button"
          variant="contained"
          sx={{
            width: "40%",
            backgroundColor: "#1976d2",
            color: "#ffffff",
            fontWeight: "bold",
            padding: "12px 0",
            fontSize: "16px",
            textTransform: "none",
            borderRadius: "8px",
            "&:hover": {
              backgroundColor: "#1565c0",
            },
          }}
          onClick={handleSave}
        >
          SAVE
        </Button>
      </Box>
    </Dialog>
  );
};

export default React.memo(SubscriptionData);
