import React, { useCallback, useEffect, useState } from "react";
import io from "socket.io-client";
import { red, purple, green, orange, yellow } from "@mui/material/colors";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  Paper,
  Grid,
  Card,
  Menu,
  MenuItem,
  CircularProgress,
  IconButton,
  Switch,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
// import Switch from '@mui/joy/Switch';
import { useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import EmptyData from "components/EmptyData";
// import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  getDatabase,
  ref,
  onValue,
  set,
  get,
  update,
  child,
  onChildAdded,
  onChildChanged,
  remove,
  onChildRemoved,
} from "firebase/database";

import PushnotificationDialogBox from "../../DialogBox/pushnotification";
import socket from "../../socket/SocketService";
const PushNotification = () => {
  const [allData, setAllData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedNotification, setselectedNotification] = useState({});

  const navigate = useNavigate();
  //   const BatteryStatusChip = ({ status }) => (
  //     <Chip
  //       label={status}
  //       style={{ backgroundColor: red[500], color: 'white' ,borderRadius: '5px', fontWeight: 'bold' }}
  //       icon={<span style={{ color: red[500], fontSize: '12px'}}>●</span>}
  //     />
  //   );
  const close = () => {
    setOpen(false);
    getData();
  };
  const openModal = () => {
    setOpen(true);
  };
  const deleteNotification = (notification) => {
    console.log(notification);
    const database = getDatabase();
    const notificationRef = ref(database, `/pushnotification/${notification.id}`);

    // Remove the vehicle entry from the database
    remove(notificationRef)
      .then((data) => {
        // Show a success message (you may implement this according to your UI)
        console.log(data);
        toast.info(`Notification removed !`, {
          autoClose: 3000,
          position: toast.POSITION.TOP_CENTER,
        });
        getData();
        // You might also want to refresh the data or update the UI accordingly
      })
      .catch((error) => {
        console.error("Error deleting vehicle:", error);
        // Handle the error (you may implement this according to your UI)
      });
  };

  const edit = (notification) => {
    console.log(notification);
    setselectedNotification(notification);
    setOpen(true);
  };
  const sendVideoAlert=()=>{
    socket.emit("newvideoalert")
  }
  const getData = () => {
    console.log("get data...");
    const db = getDatabase();
    const dbRef = ref(db, "pushnotification");
    get(dbRef).then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        // Merge keys with the data
        const mergedData = Object.entries(data).map(([key, value]) => ({
          id: key, // Add the key as 'id'
          ...value, // Spread the record data
        }));
        setAllData(mergedData);
      } else {
        setAllData([]);
      }
    });
  };

  // const fetchFullUserObject = async (user) => {
  //   console.log("userId ", userId);
  //   delete user.key;
  //   const db = getDatabase();
  //   const userRef = child(ref(db, "users"), user.userId); // Query each user by their ID
  //   const userSnapshot = await get(userRef);
  //   // console.log({...user, ...userSnapshot.val()})
  //   console.log(userSnapshot.val());
  //   // return userSnapshot.exists() ? { userId, ...userSnapshot.val() } : null;
  //   console.error({ ...userData, ...user });
  //   // userSnapshot.val()["escort"]=user
  //   var userData = userSnapshot.val();
  //   // setAllData([allData,...userSnapshot.val() ]);
  //   setAllData((prevData) => [...prevData, { userData, ...user }]);
  // };
  useEffect(() => {
    const user = localStorage.getItem("user");
    if (!user) {
      navigate("/authentication/sign-in");
    }
    getData();
  }, []);

  return (
    <DashboardLayout>
      {/* <DashboardNavbar value={searchTxt} onChange={handleSearchChange} /> */}
      {open && (
        <PushnotificationDialogBox
          open={open}
          onClose={close}
          notification={selectedNotification}
        />
      )}
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6} style={{ position: "relative", minHeight: "60vh" }}>
          {/* {loading ? (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Loading...
            </div>
          ) : ( */}
          <Grid item xs={12}>
            {/* <button className="btn btn-success" onClick={()=>openModal()}>Approved</button> */}
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{
                  textAlign: "center", // Center text
                }}
              >
                <ToastContainer />
                <MDTypography
                  variant="h6"
                  color="white"
                  sx={{
                    fontWeight: "bold", // Make text bold
                    fontSize: "1.5rem", // Adjust font size
                  }}
                >
                  PUSH NOTIFICATION DASHBOARD
                </MDTypography>
              </MDBox>
               <div  
               style={{
                display: "flex",
                "place-content": "space-between",
              }}>
               <button
                className="btn btn-success mt-5 ms-3 mb-3"
                style={{ width: 200 }}
                onClick={() => openModal()}
              >
                ADD
              </button>
               <button
                className="btn btn-success mt-5 ms-3 mb-3"
                style={{ width: 200 }}
                onClick={() => sendVideoAlert()}
              >
                SEND NEW VIDEO ALERT
              </button>
              </div>

              {allData ? (
                <MDBox>
                  <TableContainer component={Paper}>
                    <Table aria-label="user table">
                      <TableBody>
                        <TableRow>
                          <TableCell
                            align="center"
                            className="rounded"
                            sx={{ padding: "6px", color: "black", fontWeight: "bold" }}
                          >
                            Title
                          </TableCell>
                          <TableCell
                            align="center"
                            className="rounded"
                            sx={{ padding: "6px", color: "black", fontWeight: "bold" }}
                          >
                            Body
                          </TableCell>

                          <TableCell
                            align="center"
                            className="rounded"
                            sx={{ padding: "6px", color: "black", fontWeight: "bold" }}
                          >
                            Action
                          </TableCell>
                        </TableRow>
                      </TableBody>
                      {allData.map((notification, index) => (
                        <TableRow key={index} sx={{ height: "auto" }}>
                          <TableCell align="center" className="rounded" sx={{ padding: "6px" }}>
                            {notification.title}
                          </TableCell>

                          <TableCell align="center" className="rounded" sx={{ padding: "6px" }}>
                            {notification.body}
                          </TableCell>

                          <TableCell align="center" className="rounded" sx={{ padding: "6px" }}>
                            <button
                              className="btn btn-success ms-4 mb-2"
                              style={{ width: "120px" }}
                              onClick={() => edit(notification)}
                            >
                              Edit
                            </button>
                            <button
                              className="btn btn-danger ms-4 mb-2"
                              style={{ width: "120px" }}
                              onClick={() => deleteNotification(notification)}
                            >
                              Delete
                            </button>
                          </TableCell>
                        </TableRow>
                      ))}
                      {/* </TableRow>
                      </TableBody> */}
                    </Table>
                  </TableContainer>
                </MDBox>
              ) : (
                <EmptyData />
              )}
              
            </Card>
          </Grid>
          {/* )} */}
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};
export default React.memo(PushNotification);
