import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Button,
  IconButton,
} from "@mui/material";
import MDBox from "components/MDBox";
import { Close as CloseIcon } from "@mui/icons-material";
import videoImg from "../assets/video.png";

export default function File(props) {
  console.log(props);

  const videoArray = Object.values(props?.props?.videos || {});
  console.log("videoArray", videoArray);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClick = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      {videoArray.length > 0 ? (
        <button onClick={handleClick} className="btn btn-danger btn-sm">
          Open
        </button>
      ) : (
        ""
      )}

      {isModalOpen && (
        <div style={styles.modalOverlay}>
          <div style={styles.modal}>
            <div style={styles.modalHeader}>
              <IconButton onClick={handleClose} style={styles.closeButton}>
                <CloseIcon />
              </IconButton>
            </div>
            <div style={styles.modalContent}>
              <h4>Well Being Check</h4>
              {videoArray.length > 0 ? (
                <MDBox>
                  <TableContainer component={Paper}>
                    <Table aria-label="user table">
                      <TableHead align="center">
                        <TableBody>
                          <TableCell align="center">Latitude</TableCell>
                          <TableCell align="center">Longitude</TableCell>
                          <TableCell align="center">Video</TableCell>
                          {videoArray.map((video, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                height: "auto",
                              }}
                            >
                              <TableCell align="center" className="rounded" sx={{ padding: "6px" }}>
                                {video?.newCurrentLat}
                              </TableCell>
                              <TableCell align="center" className="rounded" sx={{ padding: "6px" }}>
                                {video?.newCurrentLong}
                              </TableCell>
                              <TableCell align="center" className="rounded" sx={{ padding: "6px" }}>
                                <a href={video?.videoUrl} target="_blank" rel="noopener noreferrer">
                                  <img
                                    src={videoImg}
                                    alt="video-thumbnail"
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      cursor: "pointer",
                                    }}
                                  />
                                </a>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </TableHead>
                    </Table>
                  </TableContainer>
                </MDBox>
              ) : (
                <div style={{ textAlign: "center", marginTop: "20px" }}>
                  <p>Data does not Found</p>
                </div>
              )}
            </div>
            <div style={styles.modalFooter}>
              <button onClick={handleClose} className="btn btn-dark btn-sm">
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const styles = {
  modalOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  modal: {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "5px",
    width: "40%",
    maxWidth: "800px",
    position: "relative",
  },
  modalHeader: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "10px",
  },
  closeButton: {
    fontSize: "24px",
    color: "#333",
  },
  modalContent: {
    marginBottom: "20px",
  },
  modalFooter: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
};
