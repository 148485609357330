import React from "react";
import { Chip } from "@mui/material";
import { red, orange, green } from "@mui/material/colors";

const UserFBK = ({ status }) => {
  // Determine the color based on the status valu
  const getColor = () => {
    if (status == "We are not stopping here"  || status=="I am in danger") {
      return { color: "#d50000" };
    } else if (status == "Yes, we have arrived" || status=="I am being delayed") {
      return { color: "#1b5e20" };
    } else if (status == "No Feedback" || status=="Something else") {
      return { color: "#ff6d00" };
    }
  };

  const color = getColor();

  return (
    <Chip
      label={
        <div style={{ textAlign: "center" }}>
          <span>{status}</span>
        </div>
      }
      style={{
        backgroundColor: color?.color,
        color: "white",
        borderRadius: "5px",
        fontWeight: "bold",
        height: "auto",
        padding: "5px",
      }}
    />
  );
};

export default React.memo(UserFBK);
