import React from "react";
import { Box, Avatar, Dialog, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  getDatabase,
  ref,
  onValue,
  set,
  get,
  update,
  child,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
  push,
} from "firebase/database";
import { toast } from "react-toastify";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const PushnotificationDialogBox = ({ open, onClose, notification }) => {
  const validationSchema = Yup.object({
    title: Yup.string().required("Title is required"),
    body: Yup.string().required("Body is required"),
  });
  console.log("ArrivalDialogBox");

  const formik = useFormik({
    initialValues: {
      title: notification.title || "",
      body: notification.body || "",
    },
    validationSchema,
    onSubmit: async (values) => {
      console.log(values, notification);
      const db = getDatabase();
      if (notification && notification.id) {
        const docRef = ref(db, `pushnotification/${notification.id}`);
        await update(docRef, values);
        toast.info(`Push notification is updated`, {
          autoClose: 3000,
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        const docRef = ref(db, `pushnotification`);
        await push(docRef, values);
        toast.info(`Push notification is saved`, {
          autoClose: 3000,
          position: toast.POSITION.TOP_CENTER,
        });
      }
      onClose();
    },
  });

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <IconButton
        edge="end"
        color="inherit"
        onClick={onClose}
        aria-label="close"
        sx={{
          position: "absolute",
          top: 0,
          right: 20,
        }}
      >
        <CloseIcon />
      </IconButton>

      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        sx={{
          width: "100%",
          padding: 4,
          borderRadius: 3,
          boxShadow: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 3,
          backgroundColor: "#f7f9fc",
          border: "1px solid #e0e0e0",
        }}
      >
        <TextField
          id="title"
          name="title"
          label="Title For Push Notification"
          fullWidth
          value={formik.values.title}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.title && Boolean(formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
        />

        <TextField
          id="body"
          name="body"
          label="Body For Push Notification"
          fullWidth
          multiline
          rows={8}
          value={formik.values.body}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.body && Boolean(formik.errors.body)}
          helperText={formik.touched.body && formik.errors.body}
        />

        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{
            backgroundColor: "#1976d2",
            color: "#ffffff",
            fontWeight: "bold",
            padding: "12px 0",
            fontSize: "16px",
            textTransform: "none",
            borderRadius: "8px",
            "&:hover": {
              backgroundColor: "#1565c0",
            },
          }}
        >
          SAVE
        </Button>
      </Box>
    </Dialog>
  );
};

export default React.memo(PushnotificationDialogBox);
