import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  Paper,
  Grid,
  Card,
  Menu,
  MenuItem,
  CircularProgress,
  IconButton,
  Switch,
} from "@mui/material";
import {
  getDatabase,
  ref,
  onValue,
  set,
  get,
  update,
  child,
  onChildAdded,
  onChildChanged,
  remove,
  onChildRemoved,
} from "firebase/database";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { ToastContainer, toast } from "react-toastify";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import TokenDialogBox from "../../DialogBox/tokenData";
import EmptyData from "components/EmptyData";

const Token = () => {
  const [allData, setAllData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedNotification, setselectedNotification] = useState({});

  const navigate = useNavigate();
  const close = () => {
    setOpen(false);
    getData();
  };
  const openModal = () => {
    setOpen(true);
  };
  const deleteNotification = (notification) => {
    console.log(notification);
    const database = getDatabase();
    const notificationRef = ref(database, `/token/${notification.id}`);

    remove(notificationRef)
      .then((data) => {
        console.log(data);
        toast.info(`Token removed !`, {
          autoClose: 3000,
          position: toast.POSITION.TOP_CENTER,
        });
        getData();
      })
      .catch((error) => {
        console.error("Error deleting vehicle:", error);
      });
  };

  const edit = (notification) => {
    console.log(notification);
    setselectedNotification(notification);
    setOpen(true);
  };
  const getData = () => {
    console.log("get data...");
    const db = getDatabase();
    const dbRef = ref(db, "token");
    console.log(dbRef);
    get(dbRef).then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        console.log(data);
        const mergedData = Object.entries(data).map(([key, value]) => ({
          id: key,
          ...value,
        }));
        setAllData(mergedData);
      } else {
        setAllData([]);
      }
    });
  };
  useEffect(() => {
    const user = localStorage.getItem("user");
    if (!user) {
      navigate("/authentication/sign-in");
    }
    getData();
  }, []);
  return (
    <DashboardLayout>
      {open && <TokenDialogBox open={open} onClose={close} notification={selectedNotification} />}
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6} style={{ position: "relative", minHeight: "60vh" }}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{
                  textAlign: "center",
                }}
              >
                <ToastContainer />
                <MDTypography
                  variant="h6"
                  color="white"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                  }}
                >
                  TOKEN DASHBOARD
                </MDTypography>
              </MDBox>

              <button
                className="btn btn-success mt-5 ms-3 mb-3"
                style={{ width: 200 }}
                onClick={() => openModal()}
              >
                ADD
              </button>

              {allData ? (
                <MDBox>
                  <TableContainer component={Paper}>
                    <Table aria-label="user table">
                      <TableBody>
                        <TableRow>
                          <TableCell
                            align="center"
                            className="rounded"
                            sx={{ padding: "6px", color: "black", fontWeight: "bold" }}
                          >
                            Description
                          </TableCell>
                          <TableCell
                            align="center"
                            className="rounded"
                            sx={{ padding: "6px", color: "black", fontWeight: "bold" }}
                          >
                            Token
                          </TableCell>

                          <TableCell
                            align="center"
                            className="rounded"
                            sx={{ padding: "6px", color: "black", fontWeight: "bold" }}
                          >
                            Action
                          </TableCell>
                        </TableRow>
                      </TableBody>
                      {allData.map((notification, index) => (
                        <TableRow key={index} sx={{ height: "auto" }}>
                          <TableCell align="center" className="rounded" sx={{ padding: "6px" }}>
                            {notification.title}
                          </TableCell>

                          <TableCell align="center" className="rounded" sx={{ padding: "6px" }}>
                            {notification.body}
                          </TableCell>

                          <TableCell align="center" className="rounded" sx={{ padding: "6px" }}>
                            <button
                              className="btn btn-success ms-4 mb-2"
                              style={{ width: "120px" }}
                              onClick={() => edit(notification)}
                            >
                              Edit
                            </button>
                            <button
                              className="btn btn-danger ms-4 mb-2"
                              style={{ width: "120px" }}
                              onClick={() => deleteNotification(notification)}
                            >
                              Delete
                            </button>
                          </TableCell>
                        </TableRow>
                      ))}
                      {/* </TableRow>
                    </TableBody> */}
                    </Table>
                  </TableContainer>
                </MDBox>
              ) : (
                <EmptyData />
              )}
            </Card>
          </Grid>
          {/* )} */}
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default Token;
