import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  Paper,
  Grid,
  Card,
  Menu,
  MenuItem,
  CircularProgress,
  IconButton,
  Switch,
} from "@mui/material";
import {
  getDatabase,
  ref,
  onValue,
  set,
  get,
  update,
  child,
  onChildAdded,
  onChildChanged,
  remove,
  onChildRemoved,
} from "firebase/database";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { ToastContainer, toast } from "react-toastify";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import TokenDialogBox from "../../DialogBox/tokenData";
import EmptyData from "components/EmptyData";
import SubscriptionData from "../../DialogBox/subscriptionData";

const SubscriptionInfo = () => {
  const [allData, setAllData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedNotification, setselectedNotification] = useState({});

  const navigate = useNavigate();

  const openModal = () => {
    setOpen(true);
  };
  const close = () => {
    setOpen(false);
  };

  //   const getData = () => {
  //     console.log("get data...");
  //     const db = getDatabase();
  //     const dbRef = ref(db, "subscriptionpayment");
  //     // console.log("subscriptionpayment", dbRef);

  //     get(dbRef)
  //       .then((snapshot) => {
  //         if (snapshot.exists()) {
  //           const data = snapshot.val();
  //           console.log("subscriptionpaymentdata", data);

  //           const mergedData = Object.entries(data).map(([key, value]) => ({
  //             id: key,
  //             ...value,
  //           }));

  //           const fetchUserInfoPromises = mergedData.map((paymentRequest) => {
  //             const userRef = ref(db, `users/${paymentRequest.userId}`);
  //             return get(userRef).then((userSnapshot) => {
  //               const userData = userSnapshot.exists() ? userSnapshot.val() : null;

  //               return {
  //                 ...paymentRequest,
  //                 user: userData,
  //               };
  //             });
  //           });

  //           Promise.all(fetchUserInfoPromises).then((finalData) => {
  //             setAllData(finalData);

  //             console.log("finalData", finalData);
  //           });
  //         } else {
  //           setAllData([]);
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching data:", error);
  //         setAllData([]);
  //       });
  //   };

  const getData = () => {
    console.log("get data...");
    const db = getDatabase();
    const dbRef = ref(db, "subscriptionpayment");

    get(dbRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          console.log("subscriptionpaymentdata", data);

          // Fetch users separately
          const usersRef = ref(db, "users");
          get(usersRef).then((userSnapshot) => {
            const usersData = userSnapshot.exists() ? userSnapshot.val() : {};

            const mergedData = Object.entries(data).map(([key, value]) => {
              //   console.log("values", value);
              const userId = value?.userId;
              const userData = usersData[userId] || null;

              return {
                id: key,
                ...value,
                user: userData,
              };
            });

            setAllData(mergedData);
            console.log("finalData", mergedData);
          });
        } else {
          setAllData([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setAllData([]);
      });
  };

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (!user) {
      navigate("/authentication/sign-in");
    }
    getData();
  }, []);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };
  return (
    <DashboardLayout>
      {open && <SubscriptionData open={open} onClose={close} notification={selectedNotification} />}
      <MDBox pt={6} pb={3}>
        <button
          className="btn btn-success ms-3 mb-5"
          style={{ width: 200 }}
          onClick={() => openModal()}
        >
          Subscription Details
        </button>

        <Grid container spacing={6} style={{ position: "relative", minHeight: "60vh" }}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{
                  textAlign: "center",
                }}
              >
                <ToastContainer />
                <MDTypography
                  variant="h6"
                  color="white"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                  }}
                >
                  USER SUBSCRIPTION DETAILS DASHBOARD
                </MDTypography>
              </MDBox>

              {allData ? (
                <MDBox>
                  <TableContainer component={Paper}>
                    <Table aria-label="user table">
                      <TableBody>
                        <TableRow>
                          <TableCell
                            style={{ fontWeight: "bold", color: "#1C87F9", fontSize: "14px" }}
                          >
                            SR
                          </TableCell>
                          <TableCell
                            style={{ fontWeight: "bold", color: "#1C87F9", fontSize: "14px" }}
                          >
                            Name
                          </TableCell>
                          <TableCell
                            style={{ fontWeight: "bold", color: "#1C87F9", fontSize: "14px" }}
                            align="center"
                          >
                            Email
                          </TableCell>
                          <TableCell
                            style={{ fontWeight: "bold", color: "#1C87F9", fontSize: "14px" }}
                            align="center"
                          >
                            Phone
                          </TableCell>
                          <TableCell
                            style={{ fontWeight: "bold", color: "#1C87F9", fontSize: "14px" }}
                            align="center"
                          >
                            Gender
                          </TableCell>
                          <TableCell
                            style={{ fontWeight: "bold", color: "#1C87F9", fontSize: "14px" }}
                            align="center"
                          >
                            Subscription Type
                          </TableCell>
                          <TableCell
                            style={{ fontWeight: "bold", color: "#1C87F9", fontSize: "14px" }}
                            align="center"
                          >
                            Duration
                          </TableCell>
                          <TableCell
                            style={{ fontWeight: "bold", color: "#1C87F9", fontSize: "14px" }}
                            align="center"
                          >
                            Start Date
                          </TableCell>
                          <TableCell
                            style={{ fontWeight: "bold", color: "#1C87F9", fontSize: "14px" }}
                            align="center"
                          >
                            End Date
                          </TableCell>
                        </TableRow>
                      </TableBody>
                      {allData
                        .sort((a, b) => b.currentTimeStamp - a.currentTimeStamp)
                        .map((notification, index) => (
                          <TableRow key={index} sx={{ height: "auto" }}>
                            <TableCell align="center" className="rounded" sx={{ padding: "6px" }}>
                              {index + 1}
                            </TableCell>
                            <TableCell
                              sx={{ padding: "6px", fontSize: "14px" }}
                              component="th"
                              scope="row"
                            >
                              <Avatar
                                src={notification?.user?.userImage}
                                alt={notification?.user?.userName}
                              />
                              {notification?.user?.userName}
                            </TableCell>
                            <TableCell
                              align="center"
                              className="rounded"
                              sx={{ padding: "6px", fontSize: "14px" }}
                            >
                              {notification?.user?.userEmail}
                            </TableCell>
                            <TableCell
                              align="center"
                              className="rounded"
                              sx={{ padding: "6px", fontSize: "14px" }}
                            >
                              {notification?.user?.userPhone}
                            </TableCell>
                            <TableCell
                              align="center"
                              className="rounded"
                              sx={{ padding: "6px", fontSize: "14px" }}
                            >
                              {notification?.user?.userGender}
                            </TableCell>
                            <TableCell
                              align="center"
                              className="rounded"
                              sx={{ padding: "6px", fontSize: "14px" }}
                            >
                              {notification?.type}
                            </TableCell>
                            <TableCell
                              align="center"
                              className="rounded"
                              sx={{ padding: "6px", fontSize: "14px" }}
                            >
                              {notification?.duration}
                            </TableCell>

                            <TableCell align="center" className="rounded" sx={{ padding: "6px" }}>
                              {formatDate(notification?.start)}
                            </TableCell>
                            <TableCell align="center" className="rounded" sx={{ padding: "6px" }}>
                              {formatDate(notification?.end)}
                            </TableCell>
                          </TableRow>
                        ))}
                    </Table>
                  </TableContainer>
                </MDBox>
              ) : (
                <EmptyData />
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default SubscriptionInfo;
