import axios from "axios";

export const getAddressFromCoordinates = async (latitude, longitude) => {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyB249oWvDs5DTvKrtvDstCQ-IJC4uDpWkg`
      );
      console.log(response)
      if (response.data.results && response.data.results.length > 0) {
        return response.data.results[0].formatted_address;
      } else {
        return "Unknown Place";
      }
  };

  export const getTimeFromTimestamp = (timestamp) => {
    // Convert seconds to milliseconds
    const date = new Date(timestamp * 1000);
    
    // Extract time (HH:MM:SS format)
    return date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false, // Change to true for 12-hour format
    });
  };
