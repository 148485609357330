import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import socket from "../socket/SocketService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Select,
  MenuItem as MuiMenuItem,
  FormControl,
  InputLabel,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { getDatabase, ref, onValue } from "firebase/database";

const options = ["Check Arrival", "Send Push Notification", "Delete Escort","Dispatch Security"];

const ITEM_HEIGHT = 48;

export default function LongMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [dropdownValue, setDropdownValue] = useState("");
  const [pushNotificationOptions, setPushNotificationOptions] = useState([]);
  const open = Boolean(anchorEl);

  useEffect(() => {
    const db = getDatabase();
    const dbRef = ref(db, "pushnotification");
    onValue(dbRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const optionsArray = Object.values(data);
        setPushNotificationOptions(optionsArray);
      }
    });
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (option) => {
    var socketId = props?.props?.socketId || props?.props?.userData?.socketId;
    var userId = props?.props?.userId || props?.props?.id;

    if (option === 0) {
      console.log(socketId)
      socket.emit("emitarrivalpopup", { socketId: socketId });
      toast.info(`Requested for feedback to the user`, {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });
    }
    if (option === 1) {
      setOpenModal(true);
    }
    if (option === 2) {
      socket.emit("endescort", { userId: userId });
      toast.info(`Escort is deleted`, {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });
    }
    if (option === 3) {
      var userPayload=props?.props?.userData|| props?.props;
      var payload={
        "alert_type": "Emergency",
        "alert_message": "A user needs emergency service",
        "Latitude": userPayload.currentLat,
        "Longitude": userPayload.currentLong,
        "isRead": true,
        "user_Img": userPayload.userImage,
        "user_Name": userPayload.userName,
        "user_Phone": userPayload.userPhone
      }
      socket.emit("sendalert", { payload:payload,isBroadcast:false});
      toast.info(`Security is dispatched`, {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setAnchorEl(null);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSendPushNotification = () => {
    console.log("closed..", dropdownValue);
    var userId = props?.props?.userId || props?.props?.id;
    socket.emit("pushnotificationevent", { userId: userId, action: dropdownValue });
    setOpenModal(false);
  };

  const handleDropdownChange = (event) => {
    console.log(event.target.value);
    setDropdownValue(event.target.value);
  };

  return (
    <div>
      {openModal && (
        <Dialog
          open={openModal}
          onClose={handleCloseModal}
          fullWidth
          style={{ minHeight: "400px" }}
        >
          <DialogTitle>Send Push Notification</DialogTitle>
          <DialogContent style={{ height: "140px" }}>
            <FormControl fullWidth style={{ marginTop: 5 }}>
              <InputLabel id="dropdown-label">Select Option</InputLabel>
              <Select
                labelId="dropdown-label"
                value={dropdownValue}
                label="Select Option"
                onChange={handleDropdownChange}
                style={{ width: "100%", height: 50 }}
              >
                {pushNotificationOptions.length > 0 ? (
                  pushNotificationOptions.map((option, index) => (
                    <MuiMenuItem key={index} value={option.action}>
                      {option.title}
                    </MuiMenuItem>
                  ))
                ) : (
                  <MuiMenuItem value="">No Options Available</MuiMenuItem>
                )}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                toast.info(`Push Notification Sent `, {
                  autoClose: 3000,
                  position: toast.POSITION.TOP_CENTER,
                });
                handleSendPushNotification();
              }}
              color="primary"
            >
              Send
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        slotProps={{
          paper: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "25ch",
            },
          },
        }}
      >
        {options.map((option, index) => (
          <MenuItem key={option} onClick={() => handleClose(index)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
