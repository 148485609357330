import React, { useState } from "react";
import { Box, Dialog, IconButton, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { GoogleMap, useJsApiLoader, Marker, Polyline ,InfoWindow} from "@react-google-maps/api";
import { getAddressFromCoordinates } from "Utils/helper";

const GoogleMapModal = ({ open, onClose, markerList, userLocation }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyB249oWvDs5DTvKrtvDstCQ-IJC4uDpWkg", // Replace with your actual API key
  });
  const [selectedMarker, setSelectedMarker] = useState(null); // State to track the selected marker
  const handleMarkerClick = async (selectedMarker) => {
    console.log(selectedMarker)
    const placeName = await getAddressFromCoordinates(selectedMarker.lat, selectedMarker.lng);
    console.log(placeName)
    // setMarkerTitles((prevTitles) => ({
    //   ...prevTitles,
    //   [location.id]: placeName,
    // }));
    setSelectedMarker({ ...selectedMarker, title: placeName });
  };


  if (!isLoaded) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <h1>Loading the map...</h1>
      </div>
    );
  }

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <Box
        sx={{
          position: "relative",
          width: "100vw",
          height: "100vh",
          backgroundColor: "#ffffff",
        }}
      >
        {/* Close Button */}
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 16,
            right: 16,
            zIndex: 1000,
            backgroundColor: "#ffffff",
            boxShadow: 3,
          }}
        >
          <CloseIcon />
        </IconButton>

        {/* Google Map */}
        <GoogleMap
          mapContainerStyle={{ width: "100%", height: "100%" }}
          center={userLocation[0]}
          zoom={18}
        >
          {/* Markers */}
          {markerList &&
            markerList.map((location, index) => (
              <Marker
                key={index} // Unique key for each marker
                position={{ lat: location.lat, lng: location.lng }}
                title={location.title} // Tooltip when hovering
                onClick={() => handleMarkerClick(location)}
              />
            ))}
            {selectedMarker && (
            <InfoWindow
              position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
              onCloseClick={() => setSelectedMarker(null)} // Close InfoWindow
            >
              <div>
                <h4>{selectedMarker.title}</h4>
              </div>
            </InfoWindow>
          )}

          {/* Polyline */}
          {userLocation && (
            <Polyline
              path={userLocation}
              options={{
                strokeColor: "blue",
                strokeOpacity: 0.8,
                strokeWeight: 5,
              }}
            />
          )}
        </GoogleMap>
      </Box>
    </Dialog>
  );
};

export default React.memo(GoogleMapModal);
