import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { getFirestore } from "firebase/firestore"; // Firestore API
import {
  getDatabase,
  ref,
  onValue,
  set,
  get,
  onChildAdded,
  child,
  update,
  onChildChanged,
  remove,
} from "firebase/database";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { db } from "../firebase";


const options = ["Delete User", "Payment History"];
const ITEM_HEIGHT = 48;

export default function LongMenuButton({ userId ,userDeleted}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const db = getDatabase();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async (option) => {
    setAnchorEl(null);
    if (option === 0) {
      try {
        console.log("user Id:", userId);
        // const userDocRef = doc(db, `users/${userId}`);
        // await deleteDoc(userDocRef);
        // await userDocRef.child(userId).remove(); 
        const usersRef = ref(db, `users/${userId}`);
        //  const userDocRef = db.ref(`users/${userId}`); // Reference the specific user node
        await remove(usersRef); // Remove the user data

        await userDeleted()
        console.log("User deleted successfully");
        toast.success("User deleted successfully!");
      } catch (error) {
        console.error("Error deleting user:", error.message);
        toast.error("Failed to delete user. Try again.");
      }
    } else if (option === 1) {
    }
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        slotProps={{
          paper: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "25ch",
            },
          },
        }}
      >
        {options.map((option, index) => (
          <MenuItem key={option} onClick={() => handleClose(index)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
