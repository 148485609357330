import React, { useEffect, useState, useRef } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Link, useNavigate } from "react-router-dom";
import { getDatabase, ref, get, onChildChanged ,onValue} from "firebase/database";
import { getCurrentAdminState } from "Utils/Functions";
import Button from "@mui/material/Button";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { Box, TextField, Avatar, Dialog, DialogContent } from "@mui/material";
import socket from "../../socket/SocketService";

function Online_Offline_Users() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyB249oWvDs5DTvKrtvDstCQ-IJC4uDpWkg", // Replace with the new API key
  });

  const [users, setUsers] = useState([]);
  const allData = useSelector((state) => state.data.allData);
  const [loading, setLoading] = useState(false);
  const [onlineCount, setOnlineCount] = useState(0);
  const [offlineCount, setOfflineCount] = useState(0);
  const [shortDistanceCount, setShortDistanceCount] = useState(0);
  const [longDistanceCount, setLongDistanceCount] = useState(0);
  const [virtualHomeCheckCount, setVirtualHomeCheckCount] = useState(0);
  const [virtualTravelGuardCount, setVirtualTravelGuardCount] = useState(0);
  const [filter, setFilter] = useState(true);
  const [stateName, setStateName] = useState("");
  const [startTripLocation, setStartTripLocation] = useState([]);
  const [endTripLocation, setEndTripLocation] = useState([]);
  const [mapCenter, setMapCenter] = useState({ lat: 9.082, lng: 8.6753 });
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState();
  const [open, setOpen] = useState(false);
  const [tripArray, setTripArray] = useState([]);
  const [newTripArray, setNewTripArray] = useState([]); // Store only new entries
  const [withSocketId, setWithSocketId] = useState([]);
  const [withoutSocketId, setWithoutSocketId] = useState([]);
  const [shortTripEscorted, setShortTripEscorted] = useState([]);
  const [longTripEscorted, setLongTripEscorted] = useState([]);
  const previousDataRef = useRef([]); // Ref to store the previous state of the array

  const handleClickOpen = (user) => {
    console.log("userdata...", user);

    setUserInfo(user);
    setOpen(true);
  };

  // const handleShortDistance = (userInfo) = {
  //   userInfor.shortDistance
  // }

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    socket.connect();
  }, []);

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (!user) {
      navigate("/authentication/sign-in");
    }
     getData();
  }, [allData]);

  useEffect(() => {
    if (stateName) {
      fetchCoordinates();
    }
  }, [stateName]); // Only run when `stateName` changes

  const fetchCoordinates = async () => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          stateName
        )}&key=AIzaSyB249oWvDs5DTvKrtvDstCQ-IJC4uDpWkg`
      );
      const data = await response.json();
      if (data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry.location;
        setMapCenter({ lat, lng });
      } else {
        console.log("Coordinates not found for the state:", stateName);
      }
    } catch (error) {
      console.error("Error fetching coordinates:", error);
    }
  };

  useEffect(() => {
    calculateMapCenter();
  }, [filter, users]); // Removed `mapCenter` from dependencies

  const checkArray = async () => {
    try {
      const database = getDatabase();
      const usersRef = ref(database, "/users");
      const currentAdminState = await getCurrentAdminState();
      setStateName(currentAdminState);

      get(usersRef).then((snapshot) => {
        const usersData = snapshot.val();

        for (const userId in usersData) {
          if (usersData.hasOwnProperty(userId)) {
            const user = usersData[userId];

            if (
              user.userState &&
              (allData || user.userState.toLowerCase() === currentAdminState.toLowerCase())
            ) {
              if (user.ShortDistance_Escort != null) {
                const shortDistanceInfo = user.ShortDistance_Escort;

                // const newArray = [...tripArray, shortDistanceInfo]; // Add new data
                setTripArray(shortDistanceInfo);
                console.log("this is a shortDistance Data...", shortDistanceInfo);

                //   if(ShortDistanceArray != null) {
                //     // Create a Set of IDs from array2 for quick lookup
                // const idsInArray2 = new Set(ShortDistanceArray.map((item) => item.id));
                //     const updatedArray2 = [
                //       ...array2,
                //       ...array1.filter((item) => !idsInArray2.has(item.id)),
                //     ];
                //   }            // Filter and add new items

                // Filter only new entries not present in previousDataRef
                // const newEntries = freshData.filter(
                //   (item) => !previousDataRef.current.has(JSON.stringify(item))
                // );

                // // Add new entries to the tracked Set and update states
                // newEntries.forEach((entry) =>
                //   previousDataRef.current.add(JSON.stringify(entry))
                // );

                // if (newEntries.length > 0) {
                //   setNewTripArray(newEntries); // Store new entries in a separate state
                //   setTripArray((prev) => [...prev, ...newEntries]); // Append new entries to tripArray
                // }

                for (const shortdisdata in shortDistanceInfo) {
                  const short_distance = shortDistanceInfo[shortdisdata];
                  console.log("short distance data....1", short_distance);
                }
              }
            }
          }
        }
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getData = async () => {
    setLoading(true);
    try {
      const database = getDatabase();
      const usersRef = ref(database, "/users");
      const currentAdminState = await getCurrentAdminState();
      setStateName(currentAdminState);

      get(usersRef)
        .then((snapshot) => {
          const usersData = snapshot.val();
          const usersArray = [];
          let onlineCount = 0;
          let offlineCount = 0;
          let shortDistanceCount = 0;
          let longDistanceCount = 0;
          let virtualHomeCheckCount = 0;
          let virtualTravelGuardCount = 0;

          for (const userId in usersData) {
            if (usersData.hasOwnProperty(userId)) {
              const user = usersData[userId];
              console.log("user dayta 1245....", user);

              if (
                user.userState &&
                (allData || user.userState.toLowerCase() === currentAdminState.toLowerCase())
              ) {
                usersArray.push({ ...user, id: userId });
                if (user.socketId) onlineCount++;
                else offlineCount++;

                if (user.ShortDistance_Escort != null) {
                  const shortDistanceInfo = user.ShortDistance_Escort;
                  for (const shortdisdata in shortDistanceInfo) {
                    const short_distance = shortDistanceInfo[shortdisdata];
                    console.log("short distance data....1", short_distance);
                    if (short_distance.Trip_Status != null) {
                      const userlat = short_distance.Latitude;
                      const userlog = short_distance.Longitude;
                      startTripLocation.push({ userlat, userlog });
                      console.log("starttriplocation.....", startTripLocation);

                      const stopinfo = short_distance.stop;
                      for (const stopdata in stopinfo) {
                        const stop_data = stopinfo[stopdata];
                        const endlat = stop_data.stopLatitude;
                        const endlog = stop_data.stopLongitude;
                        endTripLocation.push({ endlat, endlog });
                        console.log("endTriplocation...", endTripLocation);
                      }
                      shortDistanceCount++;
                    }

                    //  if(short_distance.)
                  }
                }
                if (user.UserTour != null) {
                  const userTourInfo = user.UserTour;
                  for (const usertour in userTourInfo) {
                    const long_distance = userTourInfo[usertour];
                    console.log("short distance data....1", long_distance);
                    longDistanceCount++;

                    //  if(short_distance.)
                  }
                }

                if (user.VirtualHomeCheck != null) {
                  const virtualHomecheckInfo = user.VirtualHomeCheck;
                  for (const data in virtualHomecheckInfo) {
                    const virtualHome = virtualHomecheckInfo[data];
                    // console.log("short distance data....1",long_distance);
                    virtualHomeCheckCount++;

                    //  if(short_distance.)
                  }
                }

                if (user.wellBeingServicesData != null) {
                  const welBeingServicesInfo = user.wellBeingServicesData;
                  for (const data in welBeingServicesInfo) {
                    const virtualHome = welBeingServicesInfo[data];
                    // console.log("short distance data....1",long_distance);
                    virtualTravelGuardCount++;

                    //  if(short_distance.)
                  }
                }

                // if (user.tripType === "long_distance") longDistanceCount++;
                // if (user.tripType === "virtual_home_check") virtualHomeCheckCount++;
                // if (user.tripType === "virtual_travel_guard") virtualTravelGuardCount++;
              }
            }
          }
          // setUsers(usersArray);
          // setOnlineCount(onlineCount);
          // setOfflineCount(offlineCount);
          setShortDistanceCount(shortDistanceCount);
          setLongDistanceCount(longDistanceCount);
          setVirtualHomeCheckCount(virtualHomeCheckCount);
          setVirtualTravelGuardCount(virtualTravelGuardCount);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error getting data: ", error);
          setLoading(false);
        });

        onValue(usersRef, (snapshot) => {
          console.log(snapshot.val(), snapshot.key);
          console.log(Object.values(snapshot.val()))
          separateSockets(Object.values(snapshot.val()))
          // setData((prevData) => ({
          //     ...prevData,
          //     [snapshot.key]: snapshot.val(),
          // }));
        });
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  const separateSockets = (usersCollection) => {
    console.log(usersCollection)
    setWithSocketId(usersCollection?.filter((usr) => usr.socketId && usr.userEmail))
    setWithoutSocketId(usersCollection?.filter((usr) => !usr.socketId && usr.userEmail))
    setShortTripEscorted(usersCollection?.filter((usr) => usr.escortInProgress ))
  };
  
  const handleFilter = (status) => {
    setFilter(status);
    if(status === "online"){
      setUsers(withSocketId)
    }
    if(status === "offline"){
      setUsers(withoutSocketId)
    }
    if(status === "shorttrip"){
      setUsers(shortTripEscorted)
    }
    if(status === "longtrip"){
      setUsers(longTripEscorted)
    }
  };

  const calculateMapCenter = () => {
    const filteredUsers =
      filter === null ? users : users.filter((user) => user.isActive === filter);

    const defaultCenter = { lat: 9.082, lng: 8.6753 };
    if (filteredUsers.length === 0) {
      if (mapCenter.lat !== defaultCenter.lat || mapCenter.lng !== defaultCenter.lng) {
        setMapCenter(defaultCenter);
      }
      return;
    }

    let sumLat = 0;
    let sumLng = 0;
    filteredUsers.forEach((user) => {
      sumLat += user.Latitude;
      sumLng += user.Longitude;
    });
    const newCenter = { lat: sumLat / filteredUsers.length, lng: sumLng / filteredUsers.length };

    if (mapCenter.lat !== newCenter.lat || mapCenter.lng !== newCenter.lng) {
      // setMapCenter(newCenter);
      setMapCenter(defaultCenter);
    }
  };

  const isLargeScreen = useMediaQuery("(min-width:1200px)");
  const mapStyles = { height: isLargeScreen ? "550px" : "400px", width: "100%" };

  if (!isLoaded) {
    return (
      <div
        style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh" }}
      >
        <h1>Loading the map...</h1>
      </div>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6} style={{ position: "relative", minHeight: "60vh" }}>
          {loading ? (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              Loading the map...
            </div>
          ) : (
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Live location
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <div style={{ marginLeft: 10, marginBottom: 10 }}>
                    <Button
                      variant="contained"
                      onClick={() => handleFilter("online")}
                      style={{
                        backgroundColor: filter=="online" ? "green" : "gray",
                        color: filter ? "white" : "black",
                      }}
                    >
                      Online ({withSocketId?.length})
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => handleFilter("offline")}
                      style={{
                        marginLeft: 6,
                        backgroundColor: filter=="offline" ? "green" : "gray",
                        color: !filter ? "white" : "black",
                      }}
                    >
                      Offline ({withoutSocketId?.length})
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => handleFilter("shorttrip")}
                      style={{
                        marginLeft: 6,
                        backgroundColor: filter=="shorttrip" ? "green" : "gray",
                        color: !filter ? "white" : "black",
                      }}
                     
                    >
                      Short Trip Distance ({shortTripEscorted.length})
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => handleFilter("longtrip")}
                      style={{
                        marginLeft: 6,
                        backgroundColor: filter=="longtrip" ? "green" : "gray",
                        color: !filter ? "white" : "black",
                      }}
                     
                    >
                      Long Trip Distance ({longDistanceCount})
                    </Button>
                    <Button
                      variant="contained"
                      style={{ marginLeft: 6, backgroundColor: "#212529", color: "white" }}
                    >
                      Virtual Home Check ({virtualHomeCheckCount})
                    </Button>
                    <Button
                      variant="contained"
                      style={{ marginLeft: 6, backgroundColor: "#ffc107", color: "white" }}
                    >
                      Virtual Travel Guard ({virtualTravelGuardCount})
                    </Button>
                  </div>
                  <GoogleMap mapContainerStyle={mapStyles} center={mapCenter} zoom={2}>
                    {users.map((user, index) => (
                      <Marker
                        key={index}
                        position={{ lat: user.currentLat , lng: user.currentLong }}
                        onClick={() => handleClickOpen(user)}
                      />
                    ))}
                    {/* {startTripLocation.map((user, index) => (
                      <Marker
                        key={index}
                        position={{ lat: user.userlat, lng: user.userlog }}
                        onClick={handleClickOpen}
                      />
                    ))}
                    {endTripLocation.map((user, index) => (
                      <Marker
                        key={index}
                        position={{ lat: user.endlat, lng: user.endlog }}
                        onClick={handleClickOpen}
                      />
                    ))} */}

                    <Dialog open={open} onClose={handleClose}>
                      {userInfo && (
                        <Box
                          sx={{
                            width: 250,
                            padding: 3,
                            borderRadius: 2,
                            boxShadow: 3,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: 2,
                            backgroundColor: "#ffffff",
                          }}
                        >
                          <Avatar
                            alt="User Photo"
                            src={userInfo.userImage}
                            sx={{ width: 80, height: 80 }}
                          />
                           <Link to={`/shortDistanceEscort/${userInfo?.key}`}>
                            <Button
                              variant="contained"
                              fullWidth
                              sx={{
                                backgroundColor: "#d32f2f",
                                color: "#ffffff",
                                fontWeight: "bold",
                                "&:hover": { backgroundColor: "#c62828" },
                              }}
                            >
                              Short Distance Escort Request
                            </Button>
                          </Link>
                          <Box
                            sx={{
                              width: "100%",
                              padding: 1,
                              backgroundColor: "#00bcd4",
                              color: "#ffffff",
                              textAlign: "center",
                              borderRadius: 1,
                            }}
                          >
                            {userInfo.userName}
                          </Box>
                          <Box
                            sx={{
                              width: "100%",
                              padding: 1,
                              backgroundColor: "#d32f2f",
                              color: "#ffffff",
                              textAlign: "center",
                              borderRadius: 1,
                            }}
                          >
                            {userInfo.userPhone}
                          </Box>
                         
                          <Link to={`/shortDistanceEscort/${userInfo?.key}`}>
                            <Button
                              variant="contained"
                              fullWidth
                              sx={{
                                backgroundColor: "#000000",
                                color: "#ffffff",
                                fontWeight: "bold",
                                "&:hover": { backgroundColor: "#333333" },
                              }}
                            >
                              Escort
                            </Button>
                          </Link>
                        </Box>
                      )}
                    </Dialog>
                  </GoogleMap>
                </MDBox>
              </Card>
            </Grid>
          )}
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Online_Offline_Users;
