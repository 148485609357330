import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  Paper,
  Grid,
  Card,
  CircularProgress,
  Pagination,
} from "@mui/material";
import {
  getDatabase,
  ref,
  query,
  orderByKey,
  startAfter,
  limitToFirst,
  get,
} from "firebase/database";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import EmptyData from "components/EmptyData";
import LongMenuButton from "Utils/userButton";
import { useNavigate } from "react-router-dom";

const Users = () => {
  const naivgate = useNavigate();
  const [users, setUsers] = useState([]);
  const [lastUserKey, setLastUserKey] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 10;
  const database = getDatabase();
  const fetchTotalUsers = async () => {
    try {
      const usersRef = ref(database, "users");
      const snapshot = await get(usersRef);
      if (snapshot.exists()) {
        setTotalUsers(Object.keys(snapshot.val()).length);
      }
    } catch (error) {
      console.error("Error fetching total users:", error);
    }
  };

  //   const fetchUsers = async () => {
  //     if (loading || !hasMore) return;

  //     setLoading(true);

  //     try {
  //       const usersRef = ref(database, "users");
  //       let userQuery = query(usersRef, orderByKey(), limitToFirst(10));

  //       if (lastUserKey) {
  //         userQuery = query(usersRef, orderByKey(), startAfter(lastUserKey), limitToFirst(10));
  //       }

  //       const snapshot = await get(userQuery);
  //       const usersData = [];

  //       snapshot.forEach((childSnapshot) => {
  //         usersData.push({ id: childSnapshot.key, ...childSnapshot.val() });
  //       });

  //       console.log("Fetched Users:", usersData);
  //       setUsers((prevUsers) => [...prevUsers, ...usersData]);

  //       console.log("users", users);
  //       if (usersData.length > 0) {
  //         setLastUserKey(usersData[usersData.length - 1].id);
  //       } else {
  //         setHasMore(false);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching users:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  const fetchUsers = async (page) => {
    setLoading(true);

    try {
      const usersRef = ref(database, "users");
      let userQuery = query(usersRef, orderByKey('createdDate'), limitToFirst(usersPerPage));

      if (lastUserKey && page > currentPage) {
        userQuery = query(
          usersRef,
          orderByKey(),
          startAfter(lastUserKey),
          limitToFirst(usersPerPage)
        );
      }

      const snapshot = await get(userQuery);
      const usersData = [];

      snapshot.forEach((childSnapshot) => {
        usersData.push({ id: childSnapshot.key, ...childSnapshot.val() });
      });

      setUsers(usersData);
      console.log("usersData", usersData);

      if (usersData.length > 0) {
        setLastUserKey(usersData[usersData.length - 1].id);
      } else {
        setLastUserKey(null);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // const user = localStorage.getItem("user");
    // if (!user) {
    //   navigate("/authentication/sign-in");
    // }
    fetchTotalUsers();
    fetchUsers(currentPage);
  }, []);
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    fetchUsers(page);
  };

  const userDeleted=()=>{
    fetchTotalUsers();
    fetchUsers(1);
  }
  return (
    <DashboardLayout>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6} style={{ position: "relative", minHeight: "60vh" }}>
          {loading ? (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Loading...
            </div>
          ) : (
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={1}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  sx={{
                    textAlign: "center", // Center text
                  }}
                >
                  {/* <ToastContainer /> */}
                  <MDTypography
                    variant="h6"
                    color="white"
                    sx={{
                      fontWeight: "bold", // Make text bold
                      fontSize: "1.5rem", // Adjust font size
                    }}
                  >
                    USERS DASHBOARD
                  </MDTypography>
                </MDBox>
                {users.length > 0 ? (
                  <MDBox>
                    <TableContainer component={Paper}>
                      <Table aria-label="user table">
                        <TableHead>
                          <TableBody>
                            <TableCell
                              style={{ fontWeight: "bold", color: "#1C87F9", fontSize: "14px" }}
                            >
                              SR
                            </TableCell>
                            <TableCell
                              style={{ fontWeight: "bold", color: "#1C87F9", fontSize: "14px" }}
                            >
                              Name
                            </TableCell>
                            <TableCell
                              style={{ fontWeight: "bold", color: "#1C87F9", fontSize: "14px" }}
                              align="center"
                            >
                              Email
                            </TableCell>
                            <TableCell
                              style={{ fontWeight: "bold", color: "#1C87F9", fontSize: "14px" }}
                              align="center"
                            >
                              Phone
                            </TableCell>
                            <TableCell
                              style={{ fontWeight: "bold", color: "#1C87F9", fontSize: "14px" }}
                              align="center"
                            >
                              Gender
                            </TableCell>
                            <TableCell
                              style={{ fontWeight: "bold", color: "#1C87F9", fontSize: "14px" }}
                              align="center"
                            >
                              Address
                            </TableCell>
                            <TableCell
                              style={{ fontWeight: "bold", color: "#1C87F9", fontSize: "14px" }}
                              align="center"
                            >
                              GSM
                            </TableCell>
                            <TableCell
                              style={{ fontWeight: "bold", color: "#1C87F9", fontSize: "14px" }}
                              align="center"
                            >
                              Device Model
                            </TableCell>
                            <TableCell
                              style={{ fontWeight: "bold", color: "#1C87F9", fontSize: "14px" }}
                              align="center"
                            >
                              subscription
                            </TableCell>
                            <TableCell
                              style={{ fontWeight: "bold", color: "#1C87F9", fontSize: "14px" }}
                              align="center"
                            >
                              Action
                            </TableCell>
                            {users.map((user, index) => (
                              <TableRow key={index} sx={{ height: "auto" }}>
                                <TableCell className="rounded" sx={{ padding: "6px" }}>
                                  {index + 1 + (currentPage - 1) * usersPerPage}
                                </TableCell>
                                <TableCell
                                  sx={{ padding: "6px", fontSize: "14px" }}
                                  component="th"
                                  scope="row"
                                >
                                  <Avatar
                                    src={user?.userImage}
                                    alt={user?.userName}
                                    // style={{ marginRight: "5px" }}
                                  />
                                  {user?.userName}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className="rounded"
                                  sx={{ padding: "6px", fontSize: "14px" }}
                                >
                                  {user?.userEmail}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className="rounded"
                                  sx={{ padding: "6px", fontSize: "14px" }}
                                >
                                  {user?.userPhone}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className="rounded"
                                  sx={{ padding: "6px", fontSize: "14px" }}
                                >
                                  {user?.userGender}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className="rounded"
                                  sx={{ padding: "6px", fontSize: "14px" }}
                                >
                                  {user?.userState}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className="rounded"
                                  sx={{ padding: "6px", fontSize: "14px" }}
                                >
                                  {user?.userGSM}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className="rounded"
                                  sx={{ padding: "6px", fontSize: "14px" }}
                                >
                                  {user?.deviceModel}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className="rounded"
                                  sx={{ padding: "6px", fontSize: "14px" }}
                                >
                                  {user?.subscription}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className="rounded"
                                  sx={{ padding: "6px" }}
                                >
                                  <LongMenuButton userId={user.id} userDeleted={userDeleted}/>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </TableHead>
                      </Table>
                    </TableContainer>
                    <MDBox display="flex" justifyContent="center" mt={3} mb={3}>
                      <Pagination
                        count={Math.ceil(totalUsers / usersPerPage)}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                      />
                    </MDBox>
                  </MDBox>
                ) : (
                  <EmptyData />
                )}
              </Card>
            </Grid>
          )}
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default Users;
