import React, { useCallback, useEffect, useState } from "react";
import io from "socket.io-client";
import { red, purple, green, orange, yellow } from "@mui/material/colors";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  Paper,
  Grid,
  Card,
  Menu,
  MenuItem,
  CircularProgress,
  IconButton,
  Switch,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
// import Switch from '@mui/joy/Switch';
import { useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import EmptyData from "components/EmptyData";
// import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  getDatabase,
  ref,
  onValue,
  set,
  get,
  update,
  child,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
} from "firebase/database";
import BatteryStatusChip from "./BatteryStatusChip";
import UserConnected from "./userConnected";
import Movement from "./movement";
import UserFBK from "./userFBK";
import Zone from "./zone";
import socket from "../../socket/SocketService";
import LongMenu from "Utils/action";
import File from "Utils/file";


const Escort = () => {
  const [status, setStatus] = useState("Connecting...");
  const [loading, setLoading] = useState(false);
  const [searchTxt, setSearchText] = useState("");
  const [users, setUsers] = useState([]);
  const { userId, wellBeingCheckId } = useParams();
  const [rows, setRows] = useState([]);
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null); // Anchor for dropdown menu
  const [selectedUser, setSelectedUser] = useState(null); // Store selected user for menu actions

  const navigate = useNavigate();
  //   const BatteryStatusChip = ({ status }) => (
  //     <Chip
  //       label={status}
  //       style={{ backgroundColor: red[500], color: 'white' ,borderRadius: '5px', fontWeight: 'bold' }}
  //       icon={<span style={{ color: red[500], fontSize: '12px'}}>●</span>}
  //     />
  //   );
  const getData = () => {
    console.log("get data...");
    const db = getDatabase();
    const escortRef = ref(db, "escort");
    const userescortRef = ref(db, "users");
    // onValue(escortRef, (snapshot) => {
    //     const operatorsData = snapshot.val();
    //     console.log(operatorsData)
    // })

    // get(escortRef)
    // .then(async(snapshot) => {
    //     if (snapshot.exists()) {
    //         console.log(snapshot.val())
    //         var escortdata= Object.values(snapshot.val())
    //         console.log(escortdata)
    //         console.log("escortdata ",escortdata.length)
    //         setAllData([])
    //         // for (let index = 0; index < escortdata.length; index++) {
    //         //     console.log("escortdata[index].userId ",escortdata[index].userId)
    //         //     if(escortdata[index].userId){
    //         //        await fetchFullUserObject(escortdata[index])
    //         //     }
    //         // }
    //         // const userIds = Object.values(snapshot.val()).map((escortdata) => escortdata.userId).filter((userId) => userId !== undefined);;
    //         // console.log(userIds)

    //         // const userPromises = userIds.map(async (userId) => {
    //         //     const userRef = child(ref(db, 'users'), userId); // Query each user by their ID
    //         //     const userSnapshot = await get(userRef);
    //         //     return userSnapshot.exists() ? { userId, ...userSnapshot.val() } : null;
    //         // });
    //         // const users = await Promise.all(userPromises);
    //         // console.log(users)
    //         // const usersWithEscortData = users.map((user) => ({
    //         //     ...user,
    //         //     escortingRef: Object.values(snapshot.val()).find(
    //         //         (userEscort) => userEscort.userId === user.userId
    //         //     ),
    //         // }));
    //         // console.log(usersWithEscortData)
    //         // setAllData(usersWithEscortData)

    //         // setData(snapshot.val());
    //     } else {
    //         console.log('No data available');
    //     }
    // })
    // .catch((error) => {
    //     console.error('Error fetching data:', error);
    // });
    onChildAdded(escortRef, async (snapshot) => {
      await fetchFullUserObject(snapshot.val());
      console.log(snapshot.val(), snapshot.key);
      // setData((prevData) => ({
      //     ...prevData,
      //     [snapshot.key]: snapshot.val(),
      // }));
    });

    onChildChanged(escortRef, (snapshot) => {
      console.log(snapshot.val(), snapshot.key);
      // setData((prevData) => ({
      //     ...prevData,
      //     [snapshot.key]: snapshot.val(),
      // }));
    });

    onChildRemoved(escortRef, (snapshot) => {
      console.log(snapshot.val(), snapshot.key);
      var removedData = snapshot.val();
      setAllData((prevData) => {
        // Filter out users with the given userId
        const filteredData = prevData.filter((item) => item.userId !== removedData.userId);
        return filteredData; // Return the updated array
      });

      // setData((prevData) => {
      //     const updatedData = { ...prevData };
      //     delete updatedData[snapshot.key];
      //     return updatedData;
      // });
    });
    onChildChanged(userescortRef, (snapshot) => {
      console.log(snapshot.val(), snapshot.key);
      // console.log(allData)
      setAllData((prevData) => {
        console.log(prevData);
        // Update userData for the matching userId
        const updatedData = prevData.map((item) => {
          if (item.userId === snapshot.key) {
            return { ...item, userData: snapshot.val() }; // Return a new object with updated userData
          }
          return item; // Return unchanged items
        });
        return updatedData; // Return the updated array
      });
      // setData((prevData) => ({
      //     ...prevData,
      //     [snapshot.key]: snapshot.val(),
      // }));
    });
  };
  const handleToggle = async (user, index) => {
    console.log(user, index, allData[index].tracking);

    // Toggle the tracking state in allData
    setAllData((prevData) => {
      const updatedData = [...prevData]; // Create a shallow copy of the previous state
      updatedData[index] = { ...updatedData[index], tracking: !updatedData[index].tracking }; // Toggle the tracking property for the specific user
      return updatedData; // Return the updated array
    });

    // Emit the updated tracking state to the server
    socket.emit("trackingData", {
      socketId: user.userData.socketId,
      tracking: !allData[index].tracking, // Send the toggled value (the new value after toggling)
    });

    toast.info(`Tracking has been updated`, {
      autoClose: 3000,
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const fetchFullUserObject = async (user) => {
    console.log("userId ", userId);
    delete user.key;
    const db = getDatabase();
    const userRef = child(ref(db, "users"), user.userId); // Query each user by their ID
    const userSnapshot = await get(userRef);
    // console.log({...user, ...userSnapshot.val()})
    console.log(userSnapshot.val());
    // return userSnapshot.exists() ? { userId, ...userSnapshot.val() } : null;
    console.error({ ...userData, ...user });
    // userSnapshot.val()["escort"]=user
    var userData = userSnapshot.val();
    // setAllData([allData,...userSnapshot.val() ]);
    setAllData((prevData) => [...prevData, { userData, ...user }]);
  };
  useEffect(() => {
    const user = localStorage.getItem("user");
    if (!user) {
      navigate("/authentication/sign-in");
    }
    getData();
  }, []);

  // const handleMenuOpen = (event, user) => {
  //   setAnchorEl(event.currentTarget);
  //   setSelectedUser(user);
  // };

  // const handleMenuClose = () => {
  //   setAnchorEl(null);
  //   setSelectedUser(null);
  // };

  // const handleCheckArrival = () => {
  //   console.log("Check Arrival clicked for user:");
  //   handleMenuClose();

  // };

  // const handleSendPushNotification = () => {
  //   console.log("Send Push Notification clicked for user:");
  //   handleMenuClose();
  // };

  return (
    <DashboardLayout>
      {/* <DashboardNavbar value={searchTxt} onChange={handleSearchChange} /> */}
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6} style={{ position: "relative", minHeight: "60vh" }}>
          {loading ? (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Loading...
            </div>
          ) : (
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  sx={{
                    textAlign: "center", // Center text
                  }}
                >
                  <ToastContainer />
                  <MDTypography
                    variant="h6"
                    color="white"
                    sx={{
                      fontWeight: "bold", // Make text bold
                      fontSize: "1.5rem", // Adjust font size
                    }}
                  >
                    ESCORT USERS DEVICE EVENTS DASHBOARD
                  </MDTypography>
                </MDBox>
                {allData ? (
                  <MDBox>
                    <TableContainer component={Paper}>
                      <Table aria-label="user table">
                        <TableHead>
                          <TableBody>
                            <TableCell>User Name</TableCell>
                            <TableCell align="center">Battery Indicator</TableCell>
                            <TableCell align="center">User Connected</TableCell>
                            {/* <TableCell align="center">Location status</TableCell> */}
                            <TableCell align="center">Zone Fencing</TableCell>
                            <TableCell align="center">Movement Indicator</TableCell>
                            <TableCell align="center">Tracking</TableCell>
                            <TableCell align="center">UserFBK</TableCell>
                            <TableCell align="center">Last Known Location</TableCell>
                            <TableCell align="center">File</TableCell>
                            <TableCell align="center">Action</TableCell>
                            {allData.map((user, index) => (
                              <TableRow key={index} sx={{ height: "auto" }}>
                                <TableCell sx={{ padding: "6px" }} component="th" scope="row">
                                  <Avatar
                                    src={user?.userData?.userImage}
                                    alt={user?.userData?.userName}
                                    style={{ marginRight: "10px" }}
                                  />
                                  {user.userData.userName}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className="rounded"
                                  sx={{ padding: "6px" }}
                                >
                                  <BatteryStatusChip
                                    status={(user?.userData?.BatteryIndicator * 100).toFixed(2)}
                                  />
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className="rounded"
                                  sx={{ padding: "6px" }}
                                >
                                  <UserConnected status={user?.userData?.socketId} />
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className="rounded"
                                  sx={{ padding: "6px" }}
                                >
                                  <Zone status={user?.zone_fenceing} />
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className="rounded"
                                  sx={{ padding: "6px" }}
                                >
                                  <Movement status={user?.userData?.is_moving} />
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className="rounded"
                                  sx={{ padding: "6px" }}
                                >
                                  <Switch
                                    checked={user?.userData?.tracking}
                                    onChange={() => handleToggle(user, index)}
                                    color="primary"
                                    borderRadius="5px"
                                  />
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className="rounded"
                                  sx={{ padding: "6px" }}
                                >
                                  <UserFBK status={user?.userData?.userFBK} />
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className="rounded"
                                  sx={{ padding: "6px" }}
                                >
                                  <Link to={`/shortDistanceEscort/${user?.userId}`}>
                                    <button className="btn btn-danger btn-sm">Track</button>
                                  </Link>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className="rounded"
                                  sx={{ padding: "6px" }}
                                >
                                  <File props={user} />
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className="rounded"
                                  sx={{ padding: "6px" }}
                                >
                                  <LongMenu props={user} />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </TableHead>
                      </Table>
                    </TableContainer>
                  </MDBox>
                ) : (
                  <EmptyData />
                )}
              </Card>
            </Grid>
          )}
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};
export default React.memo(Escort);
