import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  Paper,
  Grid,
  Card,
  Menu,
  MenuItem,
  CircularProgress,
  IconButton,
  Switch,
} from "@mui/material";
import {
  getDatabase,
  ref,
  onValue,
  set,
  get,
  update,
  child,
  onChildAdded,
  onChildChanged,
  remove,
  onChildRemoved,
} from "firebase/database";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { ToastContainer, toast } from "react-toastify";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import TokenDialogBox from "../../DialogBox/tokenData";
import EmptyData from "components/EmptyData";

const PaymentHistory = () => {
  const [allData, setAllData] = useState([]);

  const navigate = useNavigate();

  const getData = () => {
    console.log("get data...");
    const db = getDatabase();
    const dbRef = ref(db, "paymentrequest");
    console.log(dbRef);

    get(dbRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          console.log("data", data);

          const mergedData = Object.entries(data).map(([key, value]) => ({
            id: key,
            ...value,
          }));

          const fetchUserInfoPromises = mergedData.map((paymentRequest) => {
            const userRef = ref(db, `users/${paymentRequest.userId}`);
            return get(userRef).then((userSnapshot) => {
              const userData = userSnapshot.exists() ? userSnapshot.val() : null;

              return {
                ...paymentRequest,
                user: userData,
              };
            });
          });

          Promise.all(fetchUserInfoPromises).then((finalData) => {
            setAllData(finalData);

            console.log("finalData", finalData);
          });
        } else {
          setAllData([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setAllData([]);
      });
  };

  const handleApproveReject = (notification, action) => {
    const db = getDatabase();

    console.log("notification", notification);

    const dbRef = ref(db, "paymentrequest/" + notification.id);
    const removePaymentRequest = remove(dbRef);
    const userPaymentHistoryRef = ref(
      db,
      `users/${notification.userId}/paymenthistory/${notification.payment_key}`
    );
    const paymentHistoryUpdate = {
      status: action,
    };
    if (action === "rejected") {
      console.log("Token to be updated:", notification.token);
      const userTokenRef = ref(db, `users/${notification.userId}`);
      console.log("userTokenRef", userTokenRef);
      get(userTokenRef)
        .then((userTokenSnapshot) => {
          if (userTokenSnapshot.exists()) {
            const currentToken = userTokenSnapshot.val();

            console.log("Current token:", currentToken.token);
            let updatedToken = currentToken?.token + notification?.token;

            console.log("Updated token value:", updatedToken);

            update(userTokenRef, { token: updatedToken })
              .then(() => {
                console.log("User token updated with the summed value.");
              })
              .catch((error) => {
                console.error("Error updating user token:", error);
              });
          } else {
            console.log("No token found for the user.");
          }
        })
        .catch((error) => {
          console.error("Error fetching user token:", error);
        });
    }

    const updatePaymentHistory = update(userPaymentHistoryRef, paymentHistoryUpdate);

    Promise.all([updatePaymentHistory, removePaymentRequest])
      .then(() => {
        console.log(`Payment request ${action} successfully.`);
        getData();
      })
      .catch((error) => {
        console.error("Error updating data:", error);
      });
  };

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (!user) {
      navigate("/authentication/sign-in");
    }
    getData();
  }, []);
  return (
    <DashboardLayout>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6} style={{ position: "relative", minHeight: "60vh" }}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{
                  textAlign: "center",
                }}
              >
                <ToastContainer />
                <MDTypography
                  variant="h6"
                  color="white"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                  }}
                >
                  PAYMENT REQUEST DASHBOARD
                </MDTypography>
              </MDBox>

              {allData ? (
                <MDBox>
                  <TableContainer component={Paper}>
                    <Table aria-label="user table">
                      <TableBody>
                        <TableRow>
                          <TableCell
                            style={{ fontWeight: "bold", color: "#1C87F9", fontSize: "14px" }}
                          >
                            SR
                          </TableCell>
                          <TableCell
                            style={{ fontWeight: "bold", color: "#1C87F9", fontSize: "14px" }}
                          >
                            Name
                          </TableCell>
                          <TableCell
                            style={{ fontWeight: "bold", color: "#1C87F9", fontSize: "14px" }}
                            align="center"
                          >
                            Email
                          </TableCell>
                          <TableCell
                            style={{ fontWeight: "bold", color: "#1C87F9", fontSize: "14px" }}
                            align="center"
                          >
                            Phone
                          </TableCell>
                          <TableCell
                            style={{ fontWeight: "bold", color: "#1C87F9", fontSize: "14px" }}
                            align="center"
                          >
                            Gender
                          </TableCell>
                          <TableCell
                            style={{ fontWeight: "bold", color: "#1C87F9", fontSize: "14px" }}
                            align="center"
                          >
                            Address
                          </TableCell>
                          <TableCell
                            style={{ fontWeight: "bold", color: "#1C87F9", fontSize: "14px" }}
                            align="center"
                          >
                            GSM
                          </TableCell>
                          <TableCell
                            style={{ fontWeight: "bold", color: "#1C87F9", fontSize: "14px" }}
                            align="center"
                          >
                            Remaining Token
                          </TableCell>
                          <TableCell
                            style={{ fontWeight: "bold", color: "#1C87F9", fontSize: "14px" }}
                            align="center"
                          >
                            Timestamp
                          </TableCell>
                          <TableCell
                            style={{ fontWeight: "bold", color: "#1C87F9", fontSize: "14px" }}
                            align="center"
                          >
                            Token
                          </TableCell>
                          <TableCell
                            style={{ fontWeight: "bold", color: "#1C87F9", fontSize: "14px" }}
                            align="center"
                          >
                            Action
                          </TableCell>
                        </TableRow>
                      </TableBody>
                      {allData
                        .sort((a, b) => b.currentTimeStamp - a.currentTimeStamp)
                        .map((notification, index) => (
                          <TableRow key={index} sx={{ height: "auto" }}>
                            <TableCell align="center" className="rounded" sx={{ padding: "6px" }}>
                              {index + 1}
                            </TableCell>
                            <TableCell
                              sx={{ padding: "6px", fontSize: "14px" }}
                              component="th"
                              scope="row"
                            >
                              <Avatar
                                src={notification?.user?.userImage}
                                alt={notification?.user?.userName}
                              />
                              {notification?.user?.userName}
                            </TableCell>
                            <TableCell
                              align="center"
                              className="rounded"
                              sx={{ padding: "6px", fontSize: "14px" }}
                            >
                              {notification?.user?.userEmail}
                            </TableCell>
                            <TableCell
                              align="center"
                              className="rounded"
                              sx={{ padding: "6px", fontSize: "14px" }}
                            >
                              {notification?.user?.userPhone}
                            </TableCell>
                            <TableCell
                              align="center"
                              className="rounded"
                              sx={{ padding: "6px", fontSize: "14px" }}
                            >
                              {notification?.user?.userGender}
                            </TableCell>
                            <TableCell
                              align="center"
                              className="rounded"
                              sx={{ padding: "6px", fontSize: "14px" }}
                            >
                              {notification?.user?.userState}
                            </TableCell>
                            <TableCell
                              align="center"
                              className="rounded"
                              sx={{ padding: "6px", fontSize: "14px" }}
                            >
                              {notification?.user?.userGSM}
                            </TableCell>
                            <TableCell
                              align="center"
                              className="rounded"
                              sx={{ padding: "6px", fontSize: "14px" }}
                            >
                              {notification?.user?.token}
                            </TableCell>
                            <TableCell align="center" className="rounded" sx={{ padding: "6px" }}>
                              {new Date(notification.currentTimeStamp * 1000).toLocaleString()}
                            </TableCell>

                            <TableCell align="center" className="rounded" sx={{ padding: "6px" }}>
                              {notification.token}
                            </TableCell>

                            <TableCell align="center" className="rounded" sx={{ padding: "6px" }}>
                              <button
                                className="btn btn-success ms-4 mb-2"
                                style={{ width: "120px" }}
                                onClick={() => handleApproveReject(notification, "approved")}
                              >
                                Approve
                              </button>
                              <button
                                className="btn btn-danger ms-4 mb-2"
                                style={{ width: "120px" }}
                                onClick={() => handleApproveReject(notification, "rejected")}
                              >
                                Reject
                              </button>
                            </TableCell>
                          </TableRow>
                        ))}
                    </Table>
                  </TableContainer>
                </MDBox>
              ) : (
                <EmptyData />
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default PaymentHistory;
